@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
html {
  font-size: 100%;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--font-poppins);
  overflow-x: hidden;
}

a,
a:visited,
a:active {
  text-decoration: none;
}

:root {
  --color-primary: #00AB9D;
  --color-primary-dark: #00acc1;
  --color-primary-light: #64b5f6;
  --color-primary-text: #ffffff;
  --color-primary-text-dark: #b2ebf2;
  --color-primary-text-light: #ffffff;
  --color-accent: #ffc107;
  --color-accent-dark: #ffb300;
  --color-accent-light: #ffeb3b;
  --color-accent-text: #000000;
  --color-accent-text-dark: #000000;
  --color-accent-text-light: #000000;
  --color-background: #ffffff;
  --color-background-dark: #e0e0e0;
  --color-background-light: #ffffff;
  --color-background-text: #000000;
  --color-background-text-dark: #000000;
  --color-background-text-light: #000000;
  --color-background-secondary: #fafafa;
  --color-background-secondary-dark: #f5f5f5;
  --color-background-secondary-light: #ffffff;
  --color-background-secondary-text: #000000;
  --color-background-secondary-text-dark: #000000;
  --color-background-secondary-text-light: #000000;
  --color-background-tertiary: #fafafa;
  --color-background-tertiary-dark: #f5f5f5;
  --color-background-tertiary-light: #ffffff;
  --color-background-tertiary-text: #000000;
  --color-background-tertiary-text-dark: #000000;
  --color-background-tertiary-text-light: #000000;
  --color-background-quaternary: #fafafa;
  --color-background-quaternary-dark: #f5f5f5;
  --color-background-quaternary-light: #ffffff;
}

:root {
  --font-poppins: "Poppins", sans-serif;
}

body.bg-pigeon {
  background-size: 100%;
  background-position: top left;
  background-repeat: no-repeat;
}
@media (max-width: 768px) {
  body.bg-pigeon {
    background-image: none;
  }
}

main {
  position: relative;
}
@media (max-width: 950px) {
  main {
    background-size: cover;
  }
}

#fadeinContent {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #ffffff;
  z-index: 9;
  animation: fadeinall 1s normal 0.3s both;
}

#loader-wrapper {
  background: #ffffff;
  position: fixed;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 10;
}

.swal2-popup {
  width: 30em !important;
}

.swal2-title {
  font-size: 1.2em !important;
  padding-top: 1.2em !important;
}

.swal2-confirm {
  padding-top: 0.3em !important;
  padding-bottom: 0.3em !important;
}

@media (max-width: 992px) {
  .caspen-nav {
    position: fixed;
    top: 0;
    z-index: 99;
    width: 100%;
    text-align: right;
    transition: 0.3s all;
  }
  .caspen-nav.nav-bg {
    background: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(24px);
  }
  .caspen-nav.nav-white-bg {
    background: #ffffff;
  }
  .caspen-nav.nav-white-bg.nav-bg {
    background: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(24px);
  }
  .caspen-nav.openNav {
    display: block;
    height: 100%;
    background: rgb(255, 255, 255);
    overflow-y: auto;
  }
  .caspen-nav .navbar-toggler {
    border: 0;
  }
  .caspen-nav .navbar-toggler:focus {
    box-shadow: none;
  }
  .caspen-nav .navbar-toggler[aria-expanded=true] .navbar-toggler-open {
    display: none;
  }
  .caspen-nav .navbar-toggler[aria-expanded=true] .navbar-toggler-close {
    display: block;
  }
  .caspen-nav .navbar-toggler[aria-expanded=false] .navbar-toggler-open {
    display: block;
  }
  .caspen-nav .navbar-toggler[aria-expanded=false] .navbar-toggler-close {
    display: none;
  }
  .caspen-nav .navbar-collapse {
    margin-left: 100%;
    transition: 0.3s all;
  }
  .caspen-nav .navbar-collapse.show {
    margin-left: 0;
  }
  .caspen-nav .nav-item-head {
    text-transform: uppercase;
    color: #888888;
    font-weight: 700;
    font-size: 26px;
    letter-spacing: 2px;
    margin-top: 12px;
  }
  .caspen-nav .nav-item {
    padding-left: 0;
    padding-right: 0;
  }
  .caspen-nav .nav-item .nav-link {
    color: #000000;
    font-size: 20px;
    margin: 0;
  }
  .caspen-nav .nav-item.dropdown {
    position: absolute;
    bottom: 0px;
    left: 0px;
  }
}
@media (min-width: 992px) {
  .caspen-nav .navbar-nav {
    width: 100%;
    justify-content: center;
  }
}
@media (min-width: 992px) {
  .caspen-nav .ml-lg-auto {
    margin-left: auto;
  }
}
.caspen-nav .nav-link {
  color: #3d3d3d;
  padding: 12px !important;
  margin: 0 8px;
  font-size: 14px;
  border-radius: 30px;
  transition: 0.3s all;
}
@media (max-width: 992px) {
  .caspen-nav .nav-link {
    padding: 6px 12px 12px !important;
  }
}
.caspen-nav .nav-link:hover {
  background: rgba(120, 121, 241, 0.1);
}
.caspen-nav .dropdown-menu-lang.dropdown-toggle::after {
  display: none;
}
.caspen-nav .dropdown-item {
  font-size: 14px;
}
.caspen-nav .dropdown-item:hover {
  background: rgba(120, 121, 241, 0.3);
}
.caspen-nav .dropdown-item:active, .caspen-nav .dropdown-item:focus, .caspen-nav .dropdown-item.active {
  background: rgba(120, 121, 241, 0.7);
  color: #ffffff;
}

.btn-caspen-purple {
  background-color: #7879f1;
  color: #ffffff;
  border-radius: 20px;
  font-weight: 500;
  font-size: 14px;
  border: 1px solid #7879f1;
}
.btn-caspen-purple:hover, .btn-caspen-purple:focus {
  background-color: #ffffff;
  color: #5d5fef;
  border: 1px solid #5d5fef;
}
.btn-caspen-purple:hover.btn-download:before, .btn-caspen-purple:focus.btn-download:before {
  background-image: url("../images/download-icon-blue.png");
  top: 55%;
}
.btn-caspen-purple.btn-download:before {
  background-image: url("../images/download-icon-white.png");
}

.btn-caspen-white {
  background-color: #fbfbfb;
  color: #5d5fef;
  border-radius: 20px;
  font-weight: 500;
  font-size: 14px;
  border: 1px solid #3918ff;
}
.btn-caspen-white:hover, .btn-caspen-white:focus {
  background-color: #5d5fef;
  color: #ffffff;
  border: 1px solid #5d5fef;
}
.btn-caspen-white:hover.btn-download:before, .btn-caspen-white:focus.btn-download:before {
  background-image: url("../images/download-icon-white.png");
  top: 55%;
}
.btn-caspen-white.btn-download:before {
  background-image: url("../images/download-icon-blue.png");
}

.btn-apply {
  padding: 9px;
  max-width: 320px;
  width: 100%;
}

.btn-download {
  padding: 9px 56px 9px 80px;
  position: relative;
}
.btn-download:before {
  content: "";
  background-image: url("../images/download-icon-white.png");
  background-size: 24px;
  background-position: center center;
  display: block;
  position: absolute;
  height: 24px;
  width: 24px;
  left: 52px;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.3s all;
}
@media (max-width: 992px) {
  .btn-download.btn-product-download {
    padding: 8px 24px 8px 42px;
    width: 100%;
  }
  .btn-download.btn-product-download:before {
    left: 16px;
  }
}

.btn-learn {
  width: 100%;
  padding: 8px 24px 8px 24px;
  border: 1px solid #000000;
  color: #000000;
  display: none;
}
@media (max-width: 992px) {
  .btn-learn {
    display: block;
  }
}

.btn-lang {
  height: 41px;
  width: 41px;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
}
.btn-lang:hover, .btn-lang:focus {
  background-color: rgba(120, 121, 241, 0.8);
  border: 1px solid rgba(120, 121, 241, 0.8);
  color: #ffffff;
}

.btn-close-mobal {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0px;
  color: #ffffff;
  background: transparent;
}
.btn-close-mobal:hover, .btn-close-mobal:focus {
  background: rgba(255, 255, 255, 0.3);
  color: #ffffff;
}

.btn-newsletter {
  background: #998ac5;
  color: #ffffff;
  font-size: 16px;
  font-weight: 900;
  width: 100%;
  max-width: 384px;
  padding: 16px;
  border-radius: 0;
}
.btn-newsletter:hover, .btn-newsletter:focus {
  background: #6f5fa0;
  color: #ffffff;
}

.btn-request-demo {
  background: #7879f1;
  border: 1px solid #7879f1;
  border-radius: 4px;
  padding: 12px 10px;
  color: #ffffff;
  font-size: 14px;
  line-height: 1;
  white-space: nowrap;
}
.btn-request-demo:hover {
  border: 1px solid #7879f1;
  color: #7879f1;
}

.btn-download-device {
  padding: 9px 9px;
  position: relative;
  width: 100%;
  max-width: 240px;
  background: #ffffff;
  color: #000000;
  border-radius: 20px;
  border: 1px solid #ffffff;
  white-space: nowrap;
}
.btn-download-device:hover, .btn-download-device:focus {
  border: 1px solid #ffffff;
  background: rgba(255, 255, 255, 0.4);
}
.btn-download-device.purple-btn {
  border: 1px solid #7879f1;
  background: #7879f1;
  color: #ffffff;
}
.btn-download-device.purple-btn:hover, .btn-download-device.purple-btn:focus {
  border: 1px solid #7879f1;
  background: rgba(120, 121, 241, 0.8);
}

.carousel {
  margin-bottom: 32px;
}
@media (max-width: 768px) {
  .carousel {
    margin-bottom: 0;
  }
}

.carousel-item {
  position: relative;
  padding: 80px 0;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 600px;
}
@media (max-width: 768px) {
  .carousel-item {
    height: 550px;
  }
}
.carousel-item.carousel-main-item {
  background-image: url("../images/carousel-img-01.png");
}
@media (max-width: 768px) {
  .carousel-item.carousel-main-item {
    background-position: top center;
    background-image: url("../images/carousel-img-01-mobile.png");
  }
}
.carousel-item.carousel-voxis-item {
  background-image: url("../images/carousel-img-02.png");
}
@media (max-width: 768px) {
  .carousel-item.carousel-voxis-item {
    background-image: url("../images/carousel-img-02-mobile.png");
  }
  .carousel-item.carousel-voxis-item:before {
    content: "";
    display: block;
    position: absolute;
    background: #ffffff;
    width: 100%;
    height: 60px;
    bottom: -1px;
    left: 0;
  }
}
.carousel-item.carousel-pigeon-item {
  background-image: url("../images/banner-img.png");
  background-position: top left;
  background-size: contain;
}
@media (max-width: 1200px) {
  .carousel-item.carousel-pigeon-item {
    background-image: url("../images/pigeon-bg-img-03.png");
    background-position: bottom left;
    background-size: cover;
  }
}
@media (max-width: 992px) {
  .carousel-item.carousel-pigeon-item {
    background-image: url("../images/carousel-img-03-mobile.png");
    background-position: bottom left;
  }
}
.carousel-item.carousel-joinus-item {
  background-image: url("../images/carousel-img-04.png");
}
@media (max-width: 768px) {
  .carousel-item.carousel-joinus-item {
    background-image: url("../images/carousel-img-04-mobile.png");
  }
}
.carousel-item.carousel-joinus-sub-item {
  background-image: url("../images/carousel-img-01.png");
}
@media (max-width: 768px) {
  .carousel-item.carousel-joinus-sub-item {
    height: auto;
    padding-top: 120px;
    background-position: top center;
    background-image: url("../images/carousel-img-01-mobile.png");
  }
}

.carousel-content {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 16px;
  padding-right: 16px;
}
.carousel-content.carousel-main-content h2 {
  font-size: 60px;
  line-height: 1.5;
  font-weight: 700;
  text-shadow: 0px 6px 15px rgba(0, 0, 0, 0.2509803922);
  color: #ffffff;
  max-width: 600px;
}
@media (max-width: 992px) {
  .carousel-content.carousel-main-content h2 {
    font-size: 45px;
    font-weight: 700;
  }
}
.carousel-content.carousel-voxis-content h2 {
  color: #000000;
  font-size: 45px;
  font-weight: 500;
  max-width: 610px;
}
@media (max-width: 992px) {
  .carousel-content.carousel-voxis-content h2 {
    font-size: 30px;
    line-height: 1.7;
    font-weight: 700;
    text-shadow: 0px 6px 15px rgba(0, 0, 0, 0.2509803922);
    color: #ffffff;
  }
}
.carousel-content.carousel-pigeon-content {
  text-align: right;
  flex-direction: row-reverse;
}
@media (max-width: 992px) {
  .carousel-content.carousel-pigeon-content {
    text-align: left;
    justify-content: flex-start;
    flex-direction: column;
  }
}
.carousel-content.carousel-pigeon-content h2 {
  color: #000000;
  font-size: 45px;
  font-weight: 500;
  max-width: 520px;
}
@media (max-width: 992px) {
  .carousel-content.carousel-pigeon-content h2 {
    font-size: 30px;
    line-height: 1.7;
    font-weight: 700;
  }
}
.carousel-content.carousel-pigeon-content .cp-col {
  max-width: 600px;
  margin-left: auto;
}
.carousel-content.carousel-pigeon-content .cp-img-col {
  padding: 40px;
}
.carousel-content.carousel-pigeon-content .cp-img-col img {
  display: block;
  width: 100%;
  max-width: 280px;
}
@media (max-width: 768px) {
  .carousel-content.carousel-pigeon-content .cp-img-col img {
    max-width: 200px;
  }
}
.carousel-content.carousel-joinus-content h2 {
  color: #ffffff;
  font-size: 60px;
  font-weight: 500;
  line-height: 1.4;
  max-width: 530px;
  text-shadow: 0px 6px 15px rgba(0, 0, 0, 0.2509803922);
  font-weight: 700;
}
.carousel-content.carousel-joinus-content h2 span {
  font-weight: 400;
  display: block;
}
@media (max-width: 992px) {
  .carousel-content.carousel-joinus-content h2 {
    font-size: 30px;
    line-height: 1.7;
    font-weight: 700;
    text-shadow: 0px 6px 15px rgba(0, 0, 0, 0.2509803922);
    color: #ffffff;
  }
}
.carousel-content .more-link {
  color: #3d3d3d;
  text-decoration: none;
  font-size: 18px;
  padding-right: 24px;
  position: relative;
  text-transform: uppercase;
  font-weight: 300;
}
@media (max-width: 768px) {
  .carousel-content .more-link {
    font-size: 14px;
  }
}
.carousel-content .more-link.joinus-avai {
  text-transform: none;
  color: #ffffff;
}
.carousel-content .more-link.joinus-avai:before {
  background-image: url("../images/double-chevron-white.png");
}
.carousel-content .more-link span {
  font-weight: 500;
  color: #3d3d3d;
}
.carousel-content .more-link:before {
  content: "";
  background-image: url("../images/double-chevron.png");
  background-size: 12px;
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  top: 7px;
  right: 0;
  width: 12px;
  height: 12px;
  transition: 0.5s all;
}
.carousel-content .more-link:hover:before {
  right: -12px;
}

.carousel-indicators {
  margin-bottom: 0;
  bottom: -28px;
}
@media (max-width: 768px) {
  .carousel-indicators {
    bottom: 24px;
  }
}
.carousel-indicators [data-bs-target] {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #aeaeae;
  opacity: 1;
  border-top: 0;
  border-bottom: 0;
  margin-left: 6px;
  margin-right: 6px;
  transition: 0.3s all;
}
.carousel-indicators [data-bs-target].active {
  background-color: #5d5fef;
  width: 54px;
  height: 10px;
  border-radius: 15px;
  padding: 0;
}

.carousel-control-prev {
  opacity: 0 !important;
  width: 10%;
}
@media (max-width: 992px) {
  .carousel-control-prev {
    display: none;
  }
}
.carousel-control-prev:hover {
  opacity: 1 !important;
}
.carousel-control-prev:hover:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(120, 121, 241, 0.4) 0%, rgba(255, 255, 255, 0) 69%);
}

.carousel-control-next {
  opacity: 0 !important;
  width: 10%;
}
@media (max-width: 992px) {
  .carousel-control-next {
    display: none;
  }
}
.carousel-control-next:hover {
  opacity: 1 !important;
}
.carousel-control-next:hover:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to left, rgba(120, 121, 241, 0.4) 0%, rgba(255, 255, 255, 0) 69%);
}

@keyframes fadeinall {
  0% {
    opacity: 1;
  }
  97% {
    opacity: 0;
  }
  98% {
    opacity: 0;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    z-index: -1;
  }
}
.sec-our-products {
  padding-top: 24px;
  background: #ffffff;
  color: #3d3d3d;
  padding-bottom: 80px;
}
@media (max-width: 992px) {
  .sec-our-products {
    padding: 36px 0;
  }
}
.sec-our-products .selection-tab-list {
  margin-top: 100px;
  padding-left: 0;
  list-style-type: none;
}
@media (max-width: 992px) {
  .sec-our-products .selection-tab-list {
    display: flex;
    gap: 12px;
    margin-top: 8px;
  }
}
.sec-our-products .selection-tab-list .tab-item {
  display: block;
  text-decoration: none;
  color: #bababa;
  font-size: 20px;
  padding: 8px 12px 8px 0;
  margin: 12px 0;
  cursor: pointer;
}
.sec-our-products .selection-tab-list .tab-item.active, .sec-our-products .selection-tab-list .tab-item:hover {
  color: #3d3d3d;
}
@media (max-width: 992px) {
  .sec-our-products .selection-tab-list .tab-item {
    font-size: 14px;
    padding: 4px 8px;
    border: 1px solid #98a3ca;
    display: inline-block;
    border-radius: 4px;
    color: #3d3d3d;
    margin: 0;
  }
  .sec-our-products .selection-tab-list .tab-item.mobile-active {
    color: #ffffff;
    font-weight: 500;
    background-color: #5d5fef;
  }
}
.sec-our-products .sec-title {
  font-size: 26px;
  font-weight: 600;
}
@media (max-width: 768px) {
  .sec-our-products .sec-title {
    font-size: 14px;
    font-weight: 500;
  }
}
@media (max-width: 992px) {
  .sec-our-products .product-showcase-container {
    display: flex;
    flex-direction: column;
    gap: 60px;
  }
  .sec-our-products .product-showcase {
    display: flex;
    flex-direction: column;
  }
  .sec-our-products .product-showcase .product-download {
    order: 2;
    display: flex;
    gap: 12px;
  }
}
.sec-our-products .product-title {
  font-size: 36px;
}
@media (max-width: 992px) {
  .sec-our-products .product-title {
    font-size: 24px;
    font-weight: 700;
  }
}
.sec-our-products .product-desc {
  font-size: 18px;
}
@media (max-width: 992px) {
  .sec-our-products .product-desc {
    font-size: 16px;
  }
}
.sec-our-products .more-link-container {
  display: flex;
  justify-content: flex-end;
}
.sec-our-products .more-link-container .more-link {
  color: rgba(61, 61, 61, 0.3019607843);
  text-decoration: none;
  font-size: 28px;
  padding-right: 24px;
  position: relative;
}
.sec-our-products .more-link-container .more-link span {
  font-weight: 500;
  color: #3d3d3d;
}
.sec-our-products .more-link-container .more-link:before {
  content: "";
  background-image: url("../images/double-chevron.png");
  background-size: 12px;
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  top: 16px;
  right: 0;
  width: 12px;
  height: 12px;
  transition: 0.5s all;
}
.sec-our-products .more-link-container .more-link:hover:before {
  right: -12px;
}

.sec-about {
  background: #e7e7ff;
  color: #3d3d3d;
  padding-top: 200px;
  padding-bottom: 200px;
  overflow-x: hidden;
}
@media (max-width: 992px) {
  .sec-about {
    padding: 36px 0;
  }
}
.sec-about .sec-title {
  font-size: 40px;
  font-weight: 600;
}
@media (max-width: 992px) {
  .sec-about .sec-title {
    font-size: 24px;
    font-weight: 500;
    color: #001d28;
  }
}
.sec-about .about-desc-content {
  margin: auto;
  max-width: 600px;
}
.sec-about .sec-para {
  font-size: 18px;
}
@media (max-width: 992px) {
  .sec-about .sec-para {
    font-size: 16px;
    font-weight: 300;
    color: #081a2b;
  }
}
.sec-about .about-btn-container {
  max-width: 200px;
  margin: auto;
}

.sec-figure-data {
  background: #ffffff;
  color: #3d3d3d;
  padding-top: 100px;
  padding-bottom: 100px;
}
.sec-figure-data .sec-para {
  font-size: 18px;
  margin-bottom: 0;
}
.sec-figure-data .figure-data-container {
  margin-top: 48px;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 992px) {
  .sec-figure-data .figure-data-container {
    flex-wrap: wrap;
    justify-content: space-around;
  }
}
@media (max-width: 992px) {
  .sec-figure-data .figure-data-container .figure-data-content {
    width: 100%;
    max-width: 150px;
    margin-bottom: 24px;
  }
}
.sec-figure-data .figure-data-container .figure-amount {
  font-size: 64px;
  font-weight: 300;
  line-height: 1;
}
.sec-figure-data .figure-data-container .figure-title {
  font-size: 18px;
  margin-top: 16px;
}

.sec-contact {
  background: #ffffff;
  color: #3d3d3d;
  padding-top: 100px;
  padding-bottom: 100px;
}
@media (max-width: 992px) {
  .sec-contact {
    padding: 36px 0;
  }
}
.sec-contact .contact-container {
  max-width: 1100px;
  margin: auto;
}
@media (max-width: 992px) {
  .sec-contact .contact-address-container {
    display: flex;
    align-items: center;
    gap: 24px;
    justify-content: center;
  }
}
.sec-contact .contact-address-container a {
  color: #6869d9;
  font-weight: 600;
}
.sec-contact .contact-form {
  margin-bottom: 60px;
}
.sec-contact .contact-form input {
  border: 1px solid #e8e8e8;
  background: #f5f5f5;
  height: 54px;
  color: #18171d;
  font-size: 15px;
}
.sec-contact .contact-form input::placeholder {
  color: #18171d;
}
.sec-contact .contact-form textarea {
  border: 1px solid #e8e8e8;
  background: #f5f5f5;
  color: #18171d;
  font-size: 15px;
}
.sec-contact .contact-form textarea::placeholder {
  color: #18171d;
}
.sec-contact .contact-form .contact-btn {
  background: #7879f1;
  font-size: 20px;
  color: #ffffff;
  padding: 14px;
  width: 100%;
}
.sec-contact .contact-reachout-container {
  color: #374754;
  display: flex;
  gap: 36px;
  font-size: 14px;
  justify-content: center;
  margin-top: 40px;
}
.sec-contact .contact-reachout-container .contact-reachout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.sec-contact .contact-reachout-container .contact-reachout .contact-reachout-icon {
  height: 50px;
  display: flex;
  align-items: center;
}
.sec-contact .contact-reachout-container a {
  text-decoration: none;
  color: #374754;
}
.sec-contact .contact-details {
  font-size: 18px;
}
@media (max-width: 992px) {
  .sec-contact .contact-details {
    font-size: 14px;
    color: #374754;
  }
}
.sec-contact .sec-title {
  font-size: 26px;
  font-weight: 700;
}
@media (max-width: 992px) {
  .sec-contact .sec-title {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
  }
}
.sec-contact .sec-desc {
  font-size: 18px;
}
.sec-contact .contact-phone {
  font-weight: 600;
  margin-top: 12px;
}
.sec-contact .contact-phone a {
  color: #6869d9;
  font-weight: 600;
}

.flex-div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 40px;
}
.flex-div > div {
  text-align: center;
}
.flex-div span {
  font-size: 22px;
  margin-top: 10px;
}

footer {
  background: #6c6f88;
  color: #ffffff;
}
footer .social-icons-container {
  display: flex;
  gap: 16px;
}
footer .footer-divider {
  border-top: 1px solid #bababa;
  padding-top: 12px;
  padding-bottom: 24px;
  margin: 0 -24px;
}
@media (max-width: 992px) {
  footer .footer-divider {
    padding-bottom: 0;
  }
}
footer .footer-directory-container {
  display: flex;
  gap: 24px;
}
@media (max-width: 992px) {
  footer .footer-directory-container {
    flex-direction: column;
  }
}
footer .footer-directory-container .footer-directory-col {
  max-width: 170px;
  width: 100%;
}
footer .footer-directory-container .footer-directory-col .footer-directory-title {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 28px;
}
@media (max-width: 992px) {
  footer .footer-directory-container .footer-directory-col .footer-directory-title {
    margin-bottom: 12px;
  }
}
footer .footer-directory-container .footer-directory-col .footer-directory-link {
  display: block;
  text-decoration: none;
  color: #ffffff;
  font-size: 18px;
  padding: 4px 0;
  margin: 5px 0;
}
footer .newsletter-container .newsletter-txt {
  font-size: 28px;
  font-weight: 900;
  line-height: 1.3;
}
footer .newsletter-container .input-newsletter {
  width: 100%;
  max-width: 384px;
  background: transparent;
  color: #ffffff;
  border: 1px solid #bababa;
  padding: 16px;
  border-radius: 0;
  font-size: 16px;
  font-weight: 900;
}
footer .newsletter-container .input-newsletter::placeholder {
  color: #bababa;
}

.sec-voxis-download {
  background: linear-gradient(180deg, rgba(134, 194, 254, 0.8) 0%, rgba(128, 112, 244, 0.8) 100%);
  height: calc(100vh - 78px);
}
@media (max-width: 768px) {
  .sec-voxis-download {
    height: auto;
    padding: 100px 0 36px;
    background: linear-gradient(180deg, rgba(134, 194, 254, 0.8) 0%, rgba(128, 112, 244, 0.8) 100%);
  }
}
.sec-voxis-download .voxis-download-container {
  height: 100%;
  color: #000000;
}
.sec-voxis-download .voxis-download-container .vd-title {
  font-size: 45px;
  font-weight: 400;
}
.sec-voxis-download .voxis-download-container .vd-title span {
  font-weight: 600;
}
@media (max-width: 992px) {
  .sec-voxis-download .voxis-download-container .vd-title {
    font-size: 26px;
  }
}
.sec-voxis-download .voxis-download-container .vd-desc {
  max-width: 460px;
  font-size: 20px;
  line-height: 1.6;
}
@media (max-width: 992px) {
  .sec-voxis-download .voxis-download-container .vd-desc {
    font-size: 14px;
  }
}
.sec-voxis-download .voxis-download-container .vd-desc a {
  color: #000000;
}
.sec-voxis-download .voxis-download-container .vd-img img {
  width: 100%;
  display: block;
  max-width: 300px;
  margin: auto;
}
@media (max-width: 992px) {
  .sec-voxis-download .voxis-download-container .vd-img img {
    max-width: 200px;
  }
}
@media (max-width: 992px) {
  .sec-voxis-download .voxis-download-container .vd-img {
    padding-left: 40px;
  }
}
@media (max-width: 768px) {
  .sec-voxis-download .voxis-download-container .vd-img {
    margin-top: 60px;
    padding-left: 0px;
  }
}
.sec-voxis-download .download-device-btns-container {
  display: flex;
  gap: 48px;
}
@media (max-width: 992px) {
  .sec-voxis-download .download-device-btns-container {
    justify-content: center;
    gap: 24px;
  }
}
@media (max-width: 768px) {
  .sec-voxis-download .download-device-btns-container {
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
  }
}

.sec-pigeon-download {
  background: linear-gradient(270deg, #e2f6ff 0%, #78e7ff 0.01%, #8dc2ff 100%);
  height: calc(100vh - 78px);
}
@media (max-width: 768px) {
  .sec-pigeon-download {
    height: auto;
    padding: 100px 0 36px;
    background: linear-gradient(180deg, rgba(134, 194, 254, 0.8) 0%, rgba(128, 112, 244, 0.8) 100%);
  }
}
.sec-pigeon-download .pigeon-download-container {
  height: 100%;
  color: #000000;
}
.sec-pigeon-download .pigeon-download-container .pd-title {
  font-size: 45px;
  font-weight: 400;
}
.sec-pigeon-download .pigeon-download-container .pd-title span {
  font-weight: 600;
}
@media (max-width: 992px) {
  .sec-pigeon-download .pigeon-download-container .pd-title {
    font-size: 26px;
  }
}
.sec-pigeon-download .pigeon-download-container .pd-desc {
  max-width: 460px;
  font-size: 20px;
  line-height: 1.6;
  min-height: 100px;
}
@media (max-width: 992px) {
  .sec-pigeon-download .pigeon-download-container .pd-desc {
    font-size: 14px;
    min-height: 0;
  }
}
.sec-pigeon-download .pigeon-download-container .pd-desc a {
  color: #000000;
}
@media (max-width: 768px) {
  .sec-pigeon-download .pigeon-download-container .pd-img {
    margin-top: 40px;
  }
}
.sec-pigeon-download .pigeon-download-container .pd-img img {
  width: 100%;
  display: block;
  max-width: 740px;
  margin: auto;
}
.sec-pigeon-download .download-device-btns-container {
  display: flex;
  gap: 48px;
}
@media (max-width: 992px) {
  .sec-pigeon-download .download-device-btns-container {
    justify-content: center;
    gap: 24px;
  }
}
@media (max-width: 768px) {
  .sec-pigeon-download .download-device-btns-container {
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
  }
}

.sec-voxis-cam {
  background: #ffffff;
  color: #000000;
  padding: 70px 0;
}
@media (max-width: 992px) {
  .sec-voxis-cam {
    padding: 36px 0;
  }
}
.sec-voxis-cam .vc-txt {
  font-size: 45px;
  padding-left: 48px;
}
@media (max-width: 992px) {
  .sec-voxis-cam .vc-txt {
    padding: 0;
    font-size: 24px;
  }
}
.sec-voxis-cam .vc-img {
  padding: 12px 24px 0;
}
.sec-voxis-cam .vc-img img {
  display: block;
  width: 100%;
}

.sec-voxis-voice {
  background: #3a3f76;
  color: #ffffff;
  padding: 70px 0;
}
@media (max-width: 992px) {
  .sec-voxis-voice {
    padding: 36px 0 60px;
  }
}
.sec-voxis-voice .vv-txt {
  font-size: 45px;
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 992px) {
  .sec-voxis-voice .vv-txt {
    font-size: 24px;
    justify-content: flex-start;
  }
}
.sec-voxis-voice .vv-img {
  position: relative;
  max-width: 600px;
  margin: auto;
}
@media (max-width: 992px) {
  .sec-voxis-voice .vv-img {
    margin-top: 24px;
  }
}
.sec-voxis-voice .vv-img .voxis-msg-img {
  position: absolute;
  top: 0;
  left: 0;
}
.sec-voxis-voice .vv-img img {
  display: block;
  width: 100%;
}

.sec-voxis-device {
  background: #ffffff;
  padding: 70px 0;
}
@media (max-width: 992px) {
  .sec-voxis-device {
    padding: 36px 0;
  }
}
.sec-voxis-device .voxis-device-container {
  display: grid;
  height: 100%;
  color: #000000;
  grid-template-areas: "vd-content vd-img" "vd-dl-btns vd-img";
}
@media (max-width: 768px) {
  .sec-voxis-device .voxis-device-container {
    grid-template-areas: "vd-content" "vd-img" "vd-dl-btns";
  }
}
.sec-voxis-device .voxis-device-container .vd-content {
  grid-area: vd-content;
  margin-top: auto;
}
.sec-voxis-device .voxis-device-container .vd-dl-btns {
  grid-area: vd-dl-btns;
}
.sec-voxis-device .voxis-device-container .vd-img {
  grid-area: vd-img;
}
.sec-voxis-device .voxis-device-container .vd-title {
  font-size: 45px;
  font-weight: 400;
}
@media (max-width: 992px) {
  .sec-voxis-device .voxis-device-container .vd-title {
    font-size: 26px;
  }
}
.sec-voxis-device .voxis-device-container .vd-desc {
  max-width: 460px;
  font-size: 20px;
  line-height: 1.6;
}
@media (max-width: 992px) {
  .sec-voxis-device .voxis-device-container .vd-desc {
    font-size: 14px;
  }
}
.sec-voxis-device .voxis-device-container .vd-desc a {
  color: #000000;
}
.sec-voxis-device .voxis-device-container .vd-img img {
  width: 100%;
  display: block;
  max-width: 300px;
  margin: auto;
}
@media (max-width: 992px) {
  .sec-voxis-device .voxis-device-container .vd-img img {
    max-width: 210px;
  }
}
.sec-voxis-device .download-device-btns-container {
  display: flex;
  gap: 48px;
  margin-top: 12px;
}
@media (max-width: 992px) {
  .sec-voxis-device .download-device-btns-container {
    justify-content: center;
    gap: 24px;
  }
}
@media (max-width: 768px) {
  .sec-voxis-device .download-device-btns-container {
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
  }
}

.sec-pigeon-header {
  padding: 100px 0;
}
@media (max-width: 768px) {
  .sec-pigeon-header {
    padding: 100px 0 36px;
    background-image: url("../images/pigeon-bg-img-03-mobile.png");
    background-size: 100%;
    background-position: bottom left;
    background-repeat: no-repeat;
  }
}
.sec-pigeon-header .ph-img img {
  display: block;
  width: 100%;
  max-width: 400px;
  margin: auto;
}
@media (max-width: 992px) {
  .sec-pigeon-header .ph-img img {
    max-width: 240px;
    margin-left: 0;
    margin-top: 36px;
  }
}
@media (max-width: 768px) {
  .sec-pigeon-header .ph-img img {
    max-width: 180px;
  }
}
.sec-pigeon-header .ph-txt {
  font-size: 45px;
  font-weight: 500;
  text-align: right;
}
.sec-pigeon-header .ph-txt span {
  font-weight: 700;
  color: #0071ed;
}
@media (max-width: 992px) {
  .sec-pigeon-header .ph-txt {
    font-size: 26px;
  }
}
@media (max-width: 768px) {
  .sec-pigeon-header .ph-txt {
    text-align: center;
  }
}
.sec-pigeon-header .ph-request {
  display: flex;
  gap: 28px;
  justify-content: flex-end;
  margin-top: 24px;
}
@media (max-width: 768px) {
  .sec-pigeon-header .ph-request {
    justify-content: center;
    gap: 12px;
  }
}
.sec-pigeon-header .ph-request .input-request-demo {
  border: 1px solid #cacaca;
  height: 40px;
  border-radius: 4px;
  width: 100%;
  max-width: 230px;
}
.sec-pigeon-header .ph-request .input-request-demo::placeholder {
  color: #c0c4cc;
}

.sec-pigeon-support {
  margin-top: 5vw;
  padding: 70px 0;
  color: #000000;
}
@media (max-width: 992px) {
  .sec-pigeon-support {
    margin: 0;
    padding: 0 0 36px;
  }
}
.sec-pigeon-support h2.sec-title {
  margin-top: 32px;
  font-size: 45px;
  line-height: 1.4;
  font-weight: 400;
}
@media (max-width: 992px) {
  .sec-pigeon-support h2.sec-title {
    font-size: 26px;
    font-weight: 600;
  }
}
.sec-pigeon-support .sec-desc {
  font-size: 18px;
}
@media (max-width: 992px) {
  .sec-pigeon-support .sec-desc {
    font-size: 16px;
  }
}

.sec-pigeon-solution {
  background: #d7f3ff;
  padding: 60px 0;
  color: #000000;
  text-align: right;
  position: relative;
}
@media (max-width: 992px) {
  .sec-pigeon-solution {
    padding: 36px 0;
  }
}
@media (max-width: 768px) {
  .sec-pigeon-solution {
    text-align: left;
  }
}
.sec-pigeon-solution h2.sec-title {
  margin-top: 32px;
  font-size: 45px;
  line-height: 1.4;
  font-weight: 400;
}
@media (max-width: 992px) {
  .sec-pigeon-solution h2.sec-title {
    font-size: 26px;
    font-weight: 600;
  }
}
.sec-pigeon-solution .sec-desc {
  font-size: 18px;
}
@media (max-width: 992px) {
  .sec-pigeon-solution .sec-desc {
    font-size: 16px;
  }
}
.sec-pigeon-solution .ps-img {
  margin-top: -140px;
}
@media (max-width: 992px) {
  .sec-pigeon-solution .ps-img {
    margin-top: 0;
  }
}
.sec-pigeon-solution .ps-img img {
  display: block;
  width: 100%;
  max-width: 460px;
}

.sec-pigeon-knowledge {
  padding: 80px 0;
  color: #000000;
}
@media (max-width: 992px) {
  .sec-pigeon-knowledge {
    padding: 36px 0;
  }
}
.sec-pigeon-knowledge .sec-desc {
  font-size: 30px;
}
@media (max-width: 992px) {
  .sec-pigeon-knowledge .sec-desc {
    font-size: 20px;
  }
}
.sec-pigeon-knowledge h2.sec-title {
  margin-top: 32px;
  font-size: 45px;
  line-height: 1.2;
  font-weight: 600;
}
.sec-pigeon-knowledge h2.sec-title span {
  font-weight: 800;
  background: linear-gradient(180deg, #7f5adb 20.25%, #14b2e0 53.16%, #98e8ff 86.08%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media (max-width: 992px) {
  .sec-pigeon-knowledge h2.sec-title {
    font-size: 26px;
  }
}

.sec-pigeon-feedback {
  background: #d7f3ff;
  padding: 100px 0;
  text-align: right;
}
@media (max-width: 992px) {
  .sec-pigeon-feedback {
    padding: 36px 0;
  }
}
@media (max-width: 768px) {
  .sec-pigeon-feedback {
    text-align: left;
  }
}
.sec-pigeon-feedback .pf-img {
  margin-top: -200px;
}
.sec-pigeon-feedback .pf-img img {
  display: block;
  width: 100%;
  max-width: 800px;
}
@media (max-width: 992px) {
  .sec-pigeon-feedback .pf-img img {
    max-width: 300px;
  }
}
@media (max-width: 992px) {
  .sec-pigeon-feedback .pf-img {
    margin-top: 0;
  }
}
.sec-pigeon-feedback h2.sec-title {
  margin-top: 32px;
  font-size: 45px;
  line-height: 1.4;
  font-weight: 400;
}
@media (max-width: 992px) {
  .sec-pigeon-feedback h2.sec-title {
    font-size: 26px;
    font-weight: 600;
  }
}
.sec-pigeon-feedback .sec-desc {
  font-size: 18px;
}

.sec-pigeon-software {
  background: #ffffff;
  padding: 70px 0;
}
@media (max-width: 992px) {
  .sec-pigeon-software {
    padding: 36px 0;
  }
}
.sec-pigeon-software .ps-container {
  background: linear-gradient(180deg, rgba(120, 231, 255, 0) 58%, rgba(141, 194, 255, 0.46) 113.68%);
  padding: 50px;
  border-radius: 20px;
}
@media (max-width: 992px) {
  .sec-pigeon-software .ps-container {
    padding: 0 12px;
  }
}
@media (max-width: 768px) {
  .sec-pigeon-software .ps-container {
    background: none;
  }
}
.sec-pigeon-software h2.sec-title {
  color: #00a6cc;
  font-size: 38px;
  font-weight: 700;
  text-align: center;
}
@media (max-width: 992px) {
  .sec-pigeon-software h2.sec-title {
    font-size: 26px;
    text-align: left;
  }
}
.sec-pigeon-software .cs-title {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 0px;
}
@media (max-width: 992px) {
  .sec-pigeon-software .cs-title {
    font-size: 18px;
  }
}
@media (max-width: 768px) {
  .sec-pigeon-software .cs-title {
    color: #00a6cc;
  }
}
.sec-pigeon-software .cs-para {
  font-size: 18px;
}
@media (max-width: 992px) {
  .sec-pigeon-software .cs-para {
    font-size: 14px;
  }
}
@media (max-width: 768px) {
  .sec-pigeon-software .cs-para {
    color: #000000;
  }
}
.sec-pigeon-software .cs-software-icon {
  min-height: 60px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
@media (max-width: 768px) {
  .sec-pigeon-software .cs-software-icon {
    min-height: 40px;
  }
}
.sec-pigeon-software .cs-software-icon img {
  display: block;
  width: 100%;
}

.sec-pigeon-package {
  background: linear-gradient(0.95deg, #93d9ff 0.91%, #deebf2 50.1%, #8293d7 99.29%);
  padding: 70px 0;
  overflow: hidden;
}
@media (max-width: 992px) {
  .sec-pigeon-package {
    padding: 36px 0;
  }
}
.sec-pigeon-package .pp-container h2.sec-sub-title {
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}
.sec-pigeon-package .pp-container h3.sec-title {
  color: #ffffff;
  font-size: 38px;
  font-weight: 700;
  text-align: center;
}
@media (max-width: 992px) {
  .sec-pigeon-package .pp-container h3.sec-title {
    font-size: 26px;
    text-align: left;
  }
}
.sec-pigeon-package .pp-container .package-container {
  display: flex;
}
@media (max-width: 992px) {
  .sec-pigeon-package .pp-container .package-container {
    flex-direction: column;
  }
}
.sec-pigeon-package .pp-container .package-container .package-col {
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  position: relative;
}
@media (max-width: 992px) {
  .sec-pigeon-package .pp-container .package-container .package-col {
    width: 100% !important;
    margin-top: 30px !important;
    border-radius: 8px !important;
  }
}
.sec-pigeon-package .pp-container .package-container .package-col:nth-child(1) {
  margin-top: 54px;
  width: 30%;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.sec-pigeon-package .pp-container .package-container .package-col:nth-child(2) {
  height: calc(100% + 30px);
  border: 2px solid #0071ed;
  width: 40%;
}
.sec-pigeon-package .pp-container .package-container .package-col:nth-child(3) {
  margin-top: 54px;
  width: 30%;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.sec-pigeon-package .pp-container .package-container .package-col .package-highlight {
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  padding: 8px 20px;
  color: #ffffff;
  background: #0071ed;
  display: inline-flex;
  border-radius: 20px;
  position: absolute;
  top: -16px;
  left: 50%;
  transform: translateX(-50%);
}
.sec-pigeon-package .pp-container .package-container .package-col .package-title {
  color: #111827;
  font-size: 24px;
  font-weight: 600;
  padding: 40px 32px 0;
  text-align: center;
}
.sec-pigeon-package .pp-container .package-container .package-col .package-price {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 20px 32px 30px;
}
.sec-pigeon-package .pp-container .package-container .package-col .package-price .package-ultra-icon img {
  width: 100%;
  display: block;
  max-width: 92px;
  margin: auto;
}
.sec-pigeon-package .pp-container .package-container .package-col .package-price .price-amt {
  font-size: 60px;
  font-weight: 800;
  color: #111827;
  line-height: 1;
  display: flex;
  align-items: flex-start;
}
.sec-pigeon-package .pp-container .package-container .package-col .package-price .price-amt .dollar-sign {
  font-size: 36px;
  margin-right: 3px;
  font-weight: 500;
  margin-top: 5px;
}
.sec-pigeon-package .pp-container .package-container .package-col .package-price .price-unit {
  font-size: 20px;
  font-weight: 500;
  color: #6b7280;
}
.sec-pigeon-package .pp-container .package-container .package-col .package-price .price-unit span {
  display: block;
}
.sec-pigeon-package .pp-container .package-container .package-col .package-details {
  background: #f9fafb;
  padding: 32px 32px 20px;
  border-radius: 0px 0px 12px 12px;
  border-top: 2px solid #f3f4f6;
}
.sec-pigeon-package .pp-container .package-container .package-col .package-details .payment-periods {
  font-size: 14px;
  display: flex;
  justify-content: center;
}
.sec-pigeon-package .pp-container .package-container .package-col .package-details .payment-periods .switch-field {
  background: #dedede;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  margin-bottom: 24px;
  overflow: hidden;
  border-radius: 40px;
}
.sec-pigeon-package .pp-container .package-container .package-col .package-details .payment-periods .switch-field input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
}
.sec-pigeon-package .pp-container .package-container .package-col .package-details .payment-periods .switch-field label {
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  line-height: 1;
  text-align: center;
  padding: 12px 12px;
  margin-right: -1px;
  width: auto;
  transition: all 0.1s ease-in-out;
  line-height: 1.4;
  cursor: pointer;
  min-height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s all;
  font-weight: 500;
}
.sec-pigeon-package .pp-container .package-container .package-col .package-details .payment-periods .switch-field input:checked + label {
  background-color: #ffffff;
  box-shadow: none;
  border-radius: 40px;
  color: #000000;
}
.sec-pigeon-package .pp-container .package-container .package-col .package-details .btn-trial {
  color: #ffffff;
  width: 100%;
  padding: 12px 8px;
  display: flex;
  justify-content: center;
  text-align: Center;
  border-radius: 50px;
  background: linear-gradient(90deg, #3358ea 0%, #68c3f5 118.75%);
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.1);
  transition: 0.3s all;
  cursor: pointer;
}
.sec-pigeon-package .pp-container .package-container .package-col .package-details .btn-trial:hover {
  filter: brightness(1.2);
}
.sec-pigeon-package .pp-container .package-container .package-col .package-details .package-included {
  text-align: center;
  padding-top: 30px;
  font-size: 20px;
  font-weight: 600;
  color: #000000;
}
.sec-pigeon-package .pp-container .package-container .package-col .package-details ul.package-list {
  list-style-type: none;
  padding-top: 30px;
}
.sec-pigeon-package .pp-container .package-container .package-col .package-details ul.package-list li {
  position: relative;
  padding-left: 10px;
  color: #6b7280;
  padding-bottom: 16px;
  font-size: 16px;
}
.sec-pigeon-package .pp-container .package-container .package-col .package-details ul.package-list li:last-child {
  padding-bottom: 0;
}
.sec-pigeon-package .pp-container .package-container .package-col .package-details ul.package-list li:before {
  content: "";
  width: 24px;
  height: 24px;
  background-image: url("../images/check-icon.png");
  background-size: 24px 24px;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: -24px;
}
.sec-pigeon-package .aa-container {
  padding-top: 120px;
}
.sec-pigeon-package .aa-container h2.sec-title {
  color: #000000;
  font-size: 38px;
  font-weight: 700;
  text-align: center;
}
@media (max-width: 992px) {
  .sec-pigeon-package .aa-container h2.sec-title {
    font-size: 26px;
    text-align: left;
  }
}
.sec-pigeon-package .aa-container .aa-content {
  border-radius: 10px;
  background: #ffffff;
  padding: 30px;
  height: 100%;
}
@media (max-width: 992px) {
  .sec-pigeon-package .aa-container .aa-content {
    padding: 20px;
    margin-bottom: 20px;
    height: auto;
  }
}
.sec-pigeon-package .aa-container .aa-title {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 20px;
}
@media (max-width: 992px) {
  .sec-pigeon-package .aa-container .aa-title {
    font-size: 18px;
  }
}
@media (max-width: 768px) {
  .sec-pigeon-package .aa-container .aa-title {
    color: #00a6cc;
  }
}
.sec-pigeon-package .aa-container .aa-para {
  font-size: 18px;
}
@media (max-width: 992px) {
  .sec-pigeon-package .aa-container .aa-para {
    font-size: 14px;
  }
}
@media (max-width: 768px) {
  .sec-pigeon-package .aa-container .aa-para {
    color: #000000;
  }
}
.sec-pigeon-package .aa-container .aa-icon {
  min-height: 60px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
@media (max-width: 768px) {
  .sec-pigeon-package .aa-container .aa-icon {
    min-height: 40px;
  }
}
.sec-pigeon-package .aa-container .aa-icon img {
  display: block;
  width: 100%;
}

.sec-pigeon-device {
  background: linear-gradient(360deg, rgba(120, 231, 255, 0) 26.29%, rgba(141, 194, 255, 0.46) 101.77%);
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  padding: 80px 0;
}
@media (max-width: 992px) {
  .sec-pigeon-device {
    padding: 36px 0;
  }
}
.sec-pigeon-device .pigeon-device-container {
  display: grid;
  height: 100%;
  color: #000000;
  grid-template-areas: "pd-content pd-img" "pd-dl-btns pd-img";
}
@media (max-width: 768px) {
  .sec-pigeon-device .pigeon-device-container {
    grid-template-areas: "pd-content" "pd-img" "pd-dl-btns";
  }
}
.sec-pigeon-device .pigeon-device-container .pd-content {
  grid-area: pd-content;
  margin-top: auto;
}
.sec-pigeon-device .pigeon-device-container .pd-dl-btns {
  grid-area: pd-dl-btns;
}
.sec-pigeon-device .pigeon-device-container .pd-img {
  grid-area: pd-img;
}
.sec-pigeon-device .pigeon-device-container .vpdd-title {
  font-size: 45px;
  font-weight: 400;
}
@media (max-width: 992px) {
  .sec-pigeon-device .pigeon-device-container .vpdd-title {
    font-size: 26px;
  }
}
.sec-pigeon-device .pigeon-device-container .pd-desc {
  max-width: 460px;
  font-size: 20px;
  line-height: 1.6;
}
@media (max-width: 992px) {
  .sec-pigeon-device .pigeon-device-container .pd-desc {
    font-size: 14px;
  }
}
.sec-pigeon-device .pigeon-device-container .pd-desc a {
  color: #000000;
}
.sec-pigeon-device .pigeon-device-container .pd-img {
  margin-left: 40px;
}
.sec-pigeon-device .pigeon-device-container .pd-img img {
  width: 100%;
  display: block;
  margin: auto;
}
.sec-pigeon-device .download-device-btns-container {
  display: flex;
  gap: 48px;
  margin-top: 12px;
}
@media (max-width: 992px) {
  .sec-pigeon-device .download-device-btns-container {
    justify-content: center;
    gap: 24px;
  }
}
@media (max-width: 768px) {
  .sec-pigeon-device .download-device-btns-container {
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
  }
}

.sec-privacy {
  padding: 32px 0 80px;
}
@media (max-width: 992px) {
  .sec-privacy {
    padding-top: 90px;
  }
}
.sec-privacy p {
  padding-bottom: 8px;
  line-height: 1.6;
}
.sec-privacy .wpembed-toc li {
  padding-top: 8px;
  font-size: 1.1rem;
}

.sec-join-us h2 {
  font-weight: 600;
  font-size: 36px;
  text-align: center;
  padding-top: 24px;
}
@media (max-width: 992px) {
  .sec-join-us h2 {
    font-size: 24px;
  }
}
.sec-join-us h2 span {
  font-weight: 700;
  display: block;
  background: linear-gradient(180deg, #5873ff 0%, #884fd0 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.sec-join-us .job-available-info {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
}
.sec-join-us .job-available-info a {
  color: #3d3d3d;
}
.sec-join-us .job-available-container {
  margin-top: 48px;
  margin-bottom: 48px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 32px;
}
@media (max-width: 992px) {
  .sec-join-us .job-available-container {
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
    margin: 12px 0 0;
  }
}
@media (max-width: 768px) {
  .sec-join-us .job-available-container {
    grid-template-columns: repeat(auto-fill, 100%);
  }
  .sec-join-us .job-available-container.job-swipeable {
    display: flex;
    overflow-x: auto;
    scrollbar-width: none; /* Hide scrollbar for Firefox */
    -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
  }
  .sec-join-us .job-available-container.job-swipeable::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
  }
  .sec-join-us .job-available-container.job-swipeable .ja-box {
    width: 90%;
    flex: 0 0 auto;
  }
}
.sec-join-us .job-available-container .ja-box {
  background-color: #e7e7ff;
  border-radius: 30px;
  padding: 20px 24px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: 0s all;
  border: 2px solid transparent;
  text-decoration: none;
}
.sec-join-us .job-available-container .ja-box:hover, .sec-join-us .job-available-container .ja-box:focus {
  background-color: #d6d6ff;
  border: 2px solid #7879f1;
}
.sec-join-us .job-available-container .ja-box h4 {
  font-size: 22px;
  color: #3d3d3d;
  font-weight: 600;
}
.sec-join-us .job-available-container .ja-box h4 span {
  font-weight: 400;
}
@media (max-width: 992px) {
  .sec-join-us .job-available-container .ja-box h4 {
    font-size: 18px;
  }
}
.sec-join-us .job-available-container .ja-box .ja-desc {
  font-size: 20px;
  margin-top: 16px;
  text-decoration: none;
  color: #3d3d3d;
}
@media (max-width: 992px) {
  .sec-join-us .job-available-container .ja-box .ja-desc {
    margin-top: 0;
  }
}
.sec-join-us .job-available-container .ja-box .ja-desc h5 {
  font-size: 18px;
}
@media (max-width: 992px) {
  .sec-join-us .job-available-container .ja-box .ja-desc h5 {
    font-size: 16px;
  }
}
.sec-join-us .job-available-container .ja-box .ja-desc ul li {
  font-size: 16px;
}
@media (max-width: 992px) {
  .sec-join-us .job-available-container .ja-box .ja-desc ul li {
    font-size: 13px;
  }
}
.sec-join-us .job-available-container .ja-box .ja-desc ul li span {
  color: #7879f1;
  font-weight: 600;
}
.sec-join-us .job-available-container .ja-box .ja-apply {
  width: calc(100% - 32px);
  display: block;
  margin: 0 16px;
}
.sec-join-us .job-benefits-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 32px;
  margin-top: 48px;
}
@media (max-width: 992px) {
  .sec-join-us .job-benefits-container {
    margin-top: 24px;
  }
}
@media (max-width: 768px) {
  .sec-join-us .job-benefits-container {
    grid-template-columns: 1fr;
  }
}
.sec-join-us .job-benefits-container .jb-box .jb-icon {
  height: 65px;
}
.sec-join-us .job-benefits-container .jb-box h4.jb-title {
  font-size: 22px;
  font-weight: 600;
}
@media (max-width: 992px) {
  .sec-join-us .job-benefits-container .jb-box h4.jb-title {
    font-size: 18px;
  }
}
.sec-join-us .job-benefits-container .jb-box .jb-desc {
  font-size: 16px;
}
@media (max-width: 992px) {
  .sec-join-us .job-benefits-container .jb-box .jb-desc {
    font-size: 14px;
  }
}

.sec-job-desc {
  padding-top: 80px;
  padding-bottom: 100px;
}
@media (max-width: 992px) {
  .sec-job-desc {
    padding: 36px 0;
  }
}
.sec-job-desc h3 {
  font-size: 28px;
  font-weight: 600;
}
.sec-job-desc h3 span {
  font-weight: 400;
}
.sec-job-desc h4 {
  font-size: 18px;
  font-weight: 600;
}
@media (max-width: 992px) {
  .sec-job-desc h4 {
    font-size: 16px;
  }
}
.sec-job-desc ol > li {
  line-height: 1.6;
  margin-top: 8px;
  color: #3d3d3d;
}
@media (max-width: 992px) {
  .sec-job-desc ol > li {
    font-size: 14px;
  }
}

.modal-header {
  background: linear-gradient(180deg, #5873ff, #884fd0);
  color: #ffffff;
}

.modal-content {
  border: 0;
  border-radius: 12px;
}

.font-blue-bold {
  color: #0071ed;
  font-weight: 700;
}

@media (max-width: 950px) {
  .contact-details {
    width: 100%;
  }
  .contact-details .flex-div {
    flex-direction: column;
    align-items: flex-start;
  }
  .contact-details .flex-div img {
    width: 200px;
  }
}
.title-wrap {
  position: relative;
  height: 146px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
@media (max-width: 950px) {
  .title-wrap {
    height: 80px;
  }
}
.title-wrap .title-en {
  font-family: Inter;
  font-size: 98.82px;
  font-weight: 700;
  line-height: 119.6px;
  letter-spacing: 0.085em;
  text-align: center;
  color: rgb(240, 240, 241);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0px;
  z-index: -1;
}
@media (max-width: 950px) {
  .title-wrap .title-en {
    font-family: Inter;
    font-size: 48px;
    font-weight: 700;
    line-height: 58.09px;
    letter-spacing: 0.085em;
    text-align: center;
  }
}
.title-wrap .title-ch {
  font-family: Inter;
  font-size: 44px;
  font-weight: 700;
  line-height: 54px;
  letter-spacing: 0.085em;
  text-align: center;
  color: rgb(86, 11, 92);
}
@media (max-width: 950px) {
  .title-wrap .title-ch {
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    line-height: 27.87px;
    letter-spacing: 0.085em;
    text-align: center;
  }
}
.title-wrap .title-del {
  font-family: Inter;
  font-size: 20px;
  font-weight: 400;
  line-height: 24.2px;
  letter-spacing: 0.085em;
  text-align: center;
  color: rgb(120, 121, 241);
}
@media (max-width: 950px) {
  .title-wrap .title-del {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.085em;
    text-align: center;
  }
}

.title-wrap .wrap-title {
  font-size: 30px;
  font-weight: 500;
  color: #7879f1;
  padding: 50px 0 0px;
}

.title-wrap .line {
  height: 6px;
  width: 30px;
  background: #7879f1;
  margin-bottom: 40px;
}

.download-content {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  margin-top: 30px;
}
@media (max-width: 450px) {
  .download-content {
    gap: 8px;
  }
}

.download-content .item-wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 208px;
  height: 208px;
  background-color: #fff;
  border-radius: 12px;
  margin: 0 10px 20px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  overflow: hidden;
}
@media (max-width: 450px) {
  .download-content .item-wrap {
    width: 160px;
    height: 172px;
    margin: 0px;
  }
}

.item-wrap {
  position: relative;
  width: 300px;
  height: 200px;
  overflow: hidden;
  cursor: pointer;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.item,
.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
}

.item {
  z-index: 1;
}

.overlay {
  background-color: #fff;
  z-index: 2;
  top: -100%;
  transition: top 0.5s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 950px) {
  .overlay {
    justify-content: center;
    align-items: center;
  }
}
.overlay .title {
  font-family: Inter;
  font-size: 20px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.085em;
  color: #000000;
  margin-top: 40px;
  margin-bottom: 26px;
}
@media (max-width: 950px) {
  .overlay .title {
    margin-top: 0px;
    margin-bottom: 18px;
  }
}
.overlay .btn1 {
  background: #777cea;
  width: 160px;
  height: 44px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.085em;
  text-align: center;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  margin-bottom: 12px;
}
@media (max-width: 950px) {
  .overlay .btn1 {
    width: 80px;
    height: 20px;
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.085em;
    text-align: center;
  }
}

.item-wrap:hover .overlay {
  top: 0;
}

.download-content .icon-img {
  width: 80px;
  height: 80px;
}
@media (max-width: 550px) {
  .download-content .icon-img {
    width: 40px;
    height: 40px;
  }
}

.download-content .title {
  font-size: 20px;
  font-weight: 500;
  color: #333;
  margin-bottom: 0;
}
@media (max-width: 450px) {
  .download-content .title {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.085em;
    text-align: center;
  }
}

.download-content .text-content span {
  font-size: 16px;
  color: #888683;
}

.download-content .text-content span {
  font-size: 16px;
  color: #888683;
}

.download-content .btn-wrap {
  margin: 0;
  padding: 0;
  display: flex;
  margin-top: 40px;
}

.item {
  position: relative;
  z-index: 0; /* 确保项目位于覆盖层之上 */
  width: 100%;
  transition: transform 0.3s ease;
}

.download-content .btn0 {
  width: 120px;
  height: 42px;
  padding: 0;
  border-radius: 999px;
  box-shadow: 0 10px 20px 0 rgba(0, 130, 232, 0.08);
  background: #7879f1;
  line-height: 40px;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  text-align: center;
}

.download-content .btn-white {
  border: 1px solid #7879f1;
  background: #fff;
  color: #7879f1;
  margin-left: 0px;
}

.qrcode-wrap {
  display: none;
  overflow: hidden;
  width: 305px;
  height: 255px;
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  background: #fff;
}

.qrcodeIco {
  box-sizing: content-box;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translate(-50%, 0px);
  width: 150px;
  height: 150px;
}
@media (max-width: 950px) {
  .qrcodeIco {
    width: 130px;
    height: 130px;
  }
  .qrcodeIco img {
    width: 100%;
  }
}

.qrcode-wrap .qrcode-text {
  margin-top: 10px;
  font-size: 20px;
  font-weight: 500;
  color: #333;
  text-align: center;
}
@media (max-width: 950px) {
  .qrcode-wrap .qrcode-text {
    margin-top: 0px;
    font-size: 17px;
  }
}

.qrcode-wrap .qrcode {
  margin: 10px auto;
  width: 150px;
  height: 150px;
}
@media (max-width: 950px) {
  .qrcode-wrap .qrcode {
    width: 130px;
    height: 130px;
  }
  .qrcode-wrap .qrcode img {
    width: 100%;
  }
}

.other-content {
  display: flex;
  justify-content: center;
}
.other-content img {
  margin-top: 20px;
  width: 35%;
}
@media (max-width: 950px) {
  .other-content img {
    width: 90%;
  }
}
.other-content .img-box {
  display: flex;
  flex-direction: column;
}

.other-content2 {
  display: flex;
  justify-content: center;
}
.other-content2 .img-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.other-content2 .img-box .img1 {
  width: 35%;
  margin-bottom: 100px;
}
@media (max-width: 950px) {
  .other-content2 .img-box .img1 {
    width: 90%;
    margin-bottom: 40px;
  }
}
.other-content2 .img-box .img2 {
  width: 25%;
}
@media (max-width: 950px) {
  .other-content2 .img-box .img2 {
    width: 65%;
  }
}

.download-wrap {
  margin-top: 10px;
}
@media (max-width: 950px) {
  .download-wrap {
    margin-top: 20px;
  }
}

.bg1 {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: -1;
}
@media (max-width: 950px) {
  .bg1 {
    display: none;
  }
}

.bg2 {
  position: absolute;
  top: 800px;
  left: 0px;
  z-index: -1;
}
@media (max-width: 950px) {
  .bg2 {
    display: none;
  }
}

.bg3 {
  position: absolute;
  top: 1800px;
  right: 0px;
  z-index: -1;
}
@media (max-width: 950px) {
  .bg3 {
    display: none;
  }
}

.navbar {
  width: 100vw;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
@media (max-width: 950px) {
  .navbar {
    height: 48px;
    background-color: #fff;
  }
}
.navbar .container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
}
.navbar .container img {
  width: 129px;
}
@media (max-width: 950px) {
  .navbar .container img {
    width: 80px;
  }
}
@media (max-width: 950px) {
  .navbar {
    justify-content: center;
  }
}

.btn2 {
  border: 1px solid #777cea;
  width: 160px;
  height: 44px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.085em;
  text-align: center;
  color: #777cea;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
}
@media (max-width: 450px) {
  .btn2 {
    width: 120px;
    height: 32px;
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.085em;
    text-align: center;
  }
}

.header-container {
  height: 482px;
  background-image: url(../images/background_pattern.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 110%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media (max-width: 650px) {
  .header-container {
    height: 330px;
  }
}
.header-container .bg-logo {
  width: 55%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
}
@media (max-width: 550px) {
  .header-container .bg-logo {
    width: 94%;
    top: 150px;
  }
}
.header-container .logo {
  width: 162px;
  height: 99px;
  z-index: 1;
}
.header-container .title1 {
  font-family: Outfit;
  font-size: 56px;
  font-weight: 700;
  line-height: 72px;
  letter-spacing: 0.085em;
  text-align: left;
  color: rgb(255, 255, 255);
  z-index: 1;
  margin-top: 40px;
  margin-bottom: 20px;
}
@media (max-width: 650px) {
  .header-container .title1 {
    font-family: Outfit;
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    text-align: left;
    margin-top: 20px;
  }
}
.header-container .title2 {
  font-family: Outfit;
  font-size: 20px;
  font-weight: 700;
  line-height: 25.2px;
  letter-spacing: 0.085em;
  text-align: center;
  color: rgb(255, 255, 255);
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media (max-width: 650px) {
  .header-container .title2 {
    font-family: Outfit;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    text-align: center;
  }
}

.footer-container1 {
  width: 100%;
  height: 100px;
  background: rgb(248, 249, 251);
  color: rgba(0, 0, 0, 0.4);
  font-family: Avenir;
  font-size: 16px;
  font-weight: 400;
  line-height: 25.39px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  margin-top: 100px;
}
@media (max-width: 550px) {
  .footer-container1 {
    display: none;
  }
}

.footer-container2 {
  width: 100%;
  height: 160px;
  background: rgb(248, 249, 251);
  color: rgba(0, 0, 0, 0.4);
  font-family: Avenir;
  font-size: 16px;
  font-weight: 400;
  line-height: 25.39px;
  text-align: left;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  margin-top: 100px;
}
@media (max-width: 550px) {
  .footer-container2 {
    display: flex;
  }
}