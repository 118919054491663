.header-container {
  height: 482px;
  // background: rgba(120, 121, 241, 1);
  background-image: url(../images/background_pattern.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 110%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 650px) {
    height: 330px;
  }
  .bg-logo {
    width: 55%;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    @media (max-width: 550px) {
      width: 94%;
      top: 150px;
    }
  }
  .logo {
    width: 162px;
    height: 99px;
    z-index: 1;
    @media (max-width: 550px) {
      // width: 58px;
      // height: 58px;
    }
  }
  .title1 {
    font-family: Outfit;
    font-size: 56px;
    font-weight: 700;
    line-height: 72px;
    letter-spacing: 0.085em;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    z-index: 1;
    margin-top: 40px;
    margin-bottom: 20px;
    @media (max-width: 650px) {
      font-family: Outfit;
      font-size: 28px;
      font-weight: 700;
      line-height: 36px;
      text-align: left;
      margin-top: 20px;
    }
  }
  .title2 {
    font-family: Outfit;
    font-size: 20px;
    font-weight: 700;
    line-height: 25.2px;
    letter-spacing: 0.085em;
    text-align: center;
    color: rgba(255, 255, 255, 1);
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (max-width: 650px) {
      font-family: Outfit;
      font-size: 14px;
      font-weight: 700;
      line-height: 18px;
      text-align: center;
    }
  }
}
