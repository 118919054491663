.footer-container1 {
  width: 100%;
  height: 100px;
  background: rgba(248, 249, 251, 1);
  color: rgba(0, 0, 0, 0.4);
  font-family: Avenir;
  font-size: 16px;
  font-weight: 400;
  line-height: 25.39px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  margin-top: 100px;
  @media (max-width: 550px) {
    display: none;
  }
}

.footer-container2 {
  width: 100%;
  height: 160px;
  background: rgba(248, 249, 251, 1);
  color: rgba(0, 0, 0, 0.4);
  font-family: Avenir;
  font-size: 16px;
  font-weight: 400;
  line-height: 25.39px;
  text-align: left;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  margin-top: 100px;
  @media (max-width: 550px) {
    display: flex;
  }
}
